@import "variable.scss";

@font-face {
  font-family: "Gilroy-Medium";
  src: url("../fonts/GilroyMedium/gilroy-medium.eot");
  src: url("../fonts/GilroyMedium/gilroy-medium.eot?#iefix") format("embedded-opentype"),
  url("../fonts/GilroyMedium/gilroy-medium.woff2") format("woff2"),
  url("../fonts/GilroyMedium/gilroy-medium.woff") format("woff"),
  url("../fonts/GilroyMedium/gilroy-medium.ttf") format("truetype"),
  url("../fonts/GilroyMedium/gilroy-medium.svg#Gilroy-Medium") format("svg");
}

@font-face {
  font-family: "Gilroy-Thin";
  src: url("../fonts/GilroyThin/gilroythin.eot");
  src: url("../fonts/GilroyThin/gilroythin.eot?#iefix") format("embedded-opentype"),
  url("../fonts/GilroyThin/gilroythin.woff2") format("woff2"),
  url("../fonts/GilroyThin/gilroythin.woff") format("woff"),
  url("../fonts/GilroyThin/gilroythin.ttf") format("truetype"),
  url("../fonts/GilroyThin/gilroythin.svg#Gilroy-Thin") format("svg");
}

@font-face {
  font-family: "Gilroy-Regular";
  src: url("../fonts/GilroyRegular/gilroy-regular.eot");
  src: url("../fonts/GilroyRegular/gilroy-regular.eot?#iefix") format("embedded-opentype"),
  url("../fonts/GilroyRegular/gilroy-regular.woff2") format("woff2"),
  url("../fonts/GilroyRegular/gilroy-regular.woff") format("woff"),
  url("../fonts/GilroyRegular/gilroy-regular.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-Bold";
  src: url("../fonts/GilroyBold/gilroy-bold.eot");
  src: url("../fonts/GilroyBold/gilroy-bold.eot?#iefix") format("embedded-opentype"),
  url("../fonts/GilroyBold/gilroy-bold.woff2") format("woff2"),
  url("../fonts/GilroyBold/gilroy-bold.woff") format("woff"),
  url("../fonts/GilroyBold/gilroy-bold.ttf") format("truetype"),
  url("../fonts/GilroyBold/gilroy-bold.svg#Gilroy-Medium") format("svg");
}

@font-face {
  font-family: "Helvetica-Neue-Reg";
  src: url("../fonts/HelveticaNeue/helvetica-neue-reg.eot"),
  url("../fonts/HelveticaNeue/helvetica-neue-reg.woff2") format("woff2"),
  url("../fonts/HelveticaNeue/helvetica-neue-reg.woff") format("woff"),
  url("../fonts/HelveticaNeue/helvetica-neue-reg.ttf") format("truetype"),
  url("../fonts/HelveticaNeue/helvetica-neue-reg.svg#Gilroy-Medium") format("svg");
}

@font-face {
  font-family: "Helvetica-Neue-Thin";
  src: url("../fonts/HelveticaNeueThin/HelveticaNeueThin.eot"),
  url("../fonts/HelveticaNeueThin/HelveticaNeueThin.woff2") format("woff2"),
  url("../fonts/HelveticaNeueThin/HelveticaNeueThin.woff") format("woff"),
  url("../fonts/HelveticaNeueThin/HelveticaNeueThin.ttf") format("truetype"),
  url("../fonts/HelveticaNeueThin/HelveticaNeueThin.svg") format("svg");
}

body {
  font-family: $base-font-family;

  font-size: 13px;
  color: $white-color;
  height: 100%;
  background: #0e1018 url(../images/home-page.png) repeat-x top center;
}

html,
#root {
  height: 100%;
}

.container {
  width: 100%;
  max-width: 85%;

  @media only screen and (max-width: 768px) {
    max-width: 100%;
  }
}

.clearfix {
  clear: both;
}

.react-confirm-alert-body h1 {
  color: #666;
}

h1 {
  font-size: $primary-title-f-size;
  font-family: $title-font-family;
  color: $white-color;

  &.semi {
    font-size: 41px;
  }

  @media only screen and (max-width: 575px) {
    font-size: 28px;
  }
}

h2 {
  font-size: $secondary-title-f-size;
  font-family: $title-font-family;
  letter-spacing: 0.5px;

  @media only screen and (max-width: 575px) {
    font-size: 25px;
  }

  &.success-heading {
    font-size: 36px;

    @media only screen and (max-width: 575px) {
      font-size: $large-f-size;
    }
  }
}

h3 {
  font-size: $large-f-size;
  font-family: $title-font-family;
  letter-spacing: 0.5px;
  color: $white-color;

  @media only screen and (max-width: 575px) {
    &.only-heading {
      font-size: 21px;
    }
  }
}

h4 {
  font-size: $base-title-f-size;
  font-family: $medium-font-family;

  &.sub-heading {
    font-size: $large-f-size;

    @media only screen and (max-width: 575px) {
      font-size: $extra-medium-f-size;
    }
  }

  @media only screen and (max-width: 575px) {
    font-size: $extra-medium-f-size;
  }
}

h5 {
  font-size: $base-f-size;
  letter-spacing: 1px;
  font-family: $title-font-family;
}

h6 {
  font-size: $small-f-size;
  font-family: $title-font-family;
  letter-spacing: 1px;
}

a {
  color: $primary-color;
  cursor: pointer;

  &:hover {
    color: $secondary-color;
    text-decoration: none;
  }
}

p {
  font-family: $base-font-family;
  font-size: $medium-f-size !important;
  color: $gray-25;

  &.small {
    font-size: $small-f-size !important;
    // 13px

    @media only screen and (max-width: 575px) {
      font-size: 11px !important;
    }
  }

  @media only screen and (max-width: 575px) {
    font-size: $base-f-size !important;
  }
}

b {
  font-family: $title-font-family;
  letter-spacing: 0.5px;

  &.medium {
    font-family: $medium-font-family;
    font-weight: unset;
  }
}

button {
  &:focus {
    outline: 0;
  }
}

/* Header Slider */

.carousel {
  .slide {
    background: transparent !important;
    opacity: 0.3;

    &.selected {
      opacity: 1;
    }
  }

  .control-dots {
    bottom: 30px !important;
    margin-left: 27% !important;
  }

  &.carousel-slider {
    .control-arrow {
      bottom: 25px !important;
      top: 44px !important;
      padding: 0 !important;
    }
  }

  .slider-wrapper.axis-horizontal .slider .slide {
    padding: 0 10px 0;

    img {
      margin: 25px 0;
      box-shadow: 0 6px 13px 5px rgba(0, 0, 0, 0.5);
      border-radius: 10px;

      @media only screen and (max-width: 1130px) {
        min-height: 230px;
      }
      @media only screen and (max-width: 575px) {
        min-height: unset;
        margin: 40px 0 25px;
      }
    }
  }

  .control-arrow {
    opacity: 1 !important;
    top: 20px;

    &:hover {
      background-color: transparent !important;
    }

    &:before {
      font-size: 20px;
      color: #fff;
      display: block;
      font-family: revicons;
      text-align: center;
      z-index: 2;
      position: relative;
    }
  }

  &.carousel-slider .control-prev.control-arrow {
    left: 4.3%;

    &:before {
      padding: 0;
      content: url(../images/left-arrow.png) !important;
      border: 0 !important;
      position: relative;
      margin: 0;
    }
  }

  &.carousel-slider .control-next.control-arrow {
    right: 4.3%;

    &:before {
      padding: 0;
      content: url(../images/right-arrow.png) !important;
      border: 0 !important;
      position: relative;
      margin: 0;
    }
  }

  @media only screen and (max-width: 768px) {
    .carousel .slider-wrapper.axis-horizontal .slider .slide {
      min-width: 100% !important;
    }
  }
  @media only screen and (max-width: 575.94px) {
    .slider-wrapper.axis-horizontal .slider .slide {
      padding: 0 15px 0;

      &.selected {
        img {
          transform: scale(1.1);
        }
      }

      img {
        box-shadow: 0 0px 10px 5px rgba(0, 0, 0, 0.5) !important;
        border-radius: 3px;
      }
    }

    .control-dots {
      display: none !important;
    }

    &.carousel-slider .control-next.control-arrow {
      &:before {
        display: none;
      }
    }

    &.carousel-slider .control-prev.control-arrow {
      &:before {
        display: none;
      }
    }
  }

  @media only screen and (max-width: 420px) {
    .slider-wrapper.axis-horizontal .slider .slide {
      padding: 0 12px 0;
    }
  }
}

.react-multiple-carousel__arrow {
  background: none !important;

  &:hover {
    background: none;
  }
}

.react-multi-carousel-list {
  button {
    &:focus {
      outline: 0 !important;
    }
  }
}

.react-multiple-carousel__arrow::before {
  font-size: 30px !important;
}

.circle__Wrapper-sc-16bbsoy-0 {
  margin: 0px auto !important;
}

/* Date picker */
input {
  &:focus {
    outline: none !important;
  }
}

.react-date-picker__wrapper {
  border: none !important;
}

.birth-date {
  padding: 0px 12px !important;
  color: $gray-96;
}

.react-date-picker__inputGroup {
  input {
    &::placeholder {
      text-transform: uppercase;
      font-size: 14px;
    }
  }
}

.react-date-picker__inputGroup__input:invalid {
  // background: none !important;
}

@media only screen and (max-width: 1440px) {
  .react-calendar {
    width: 250px !important;
    max-width: 100% !important;
  }

  .react-calendar__tile {
    padding: 10px !important;
    font-size: $base-f-size;
  }
}

@media only screen and (max-width: 575px) {
  .react-calendar {
    width: 220px !important;
    max-width: 100% !important;
  }

  .react-calendar__month-view__weekdays__weekday {
    font-size: $e-small-f-size;
  }

  .react-calendar__tile {
    padding: 5px !important;
    font-size: $small-f-size;
  }
}

.form-control {
  font-family: $base-font-family;

  &:focus {
    box-shadow: 0 0 4px 1px rgba(61, 142, 255, 0.3);
  }
}

.fa-exclamation-triangle {
  font-size: 40px;
  text-align: center;
  margin-bottom: 10px;
}

.shadow-padding {
  .flickity-viewport {
    height: 170px !important;
    padding-top: 10px;

    .flickity-slider {
      left: 8.5% !important;

      @media only screen and (max-width: 768px) {
        left: 15px !important;
      }
    }
  }
}

.big-image-slider {
  .flickity-viewport {
    height: 350px !important;
  }

  .flickity-slider {
    left: 10.1% !important;
  }

  @media only screen and (max-width: 1600px) {
    .flickity-slider {
      left: 10.9% !important;
    }
  }

  @media only screen and (max-width: 1200px) {
    .flickity-slider {
      left: 11.7% !important;
    }
  }

  @media only screen and (max-width: 1040px) {
    .flickity-slider {
      left: 11% !important;
    }

    .flickity-viewport {
      height: 320px !important;
    }
  }

  @media only screen and (max-width: 768px) {
    .flickity-slider {
      left: 38px !important;
    }

    .flickity-viewport {
      height: 420px !important;
    }

    .slide-inner {
      margin-left: -30px !important;
      //margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }

  @media only screen and (max-width: 575px) {
    .flickity-viewport {
      height: 370px !important;
    }
  }
}

.small-image-slider {
  .flickity-slider {
    left: 8.6% !important;
  }

  @media only screen and (max-width: 1600px) {
    .flickity-slider {
      left: 8.9% !important;
    }
  }

  @media only screen and (max-width: 1440px) {
    .flickity-slider {
      left: 9.2% !important;
    }
  }

  @media only screen and (max-width: 1024px) {
    .flickity-slider {
      left: 10% !important;
    }
  }

  @media only screen and (max-width: 768px) {
    .flickity-slider {
      left: 35px !important;
    }

    .slide-inner {
      margin-left: -18px !important;
      //margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }

  @media only screen and (max-width: 380px) {
    .flickity-slider {
      left: 30px !important;
    }

    .slide-inner {
      margin-left: -16px !important;
      //margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
}

.gftRWT .white-panel .form-group .form-control {
  padding: 0 !important;
}

input:-internal-autofill-selected {
  color: $gray-38 !important;
  background: $white-color !important;
}

.do-not-sell {
  max-width: 600px;
  padding: 3rem;
  border-radius: 15px;
  // box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.3);
}

.table.CAPrivacy {
  color: $gray-25;
  border: 1px solid $gray-25;

  th {
    letter-spacing: 1px;
    border: 1px solid $gray-25;
  }

  td {
    border: 1px solid $gray-25;
  }
}

#tooltip {
  border: 2px solid $primary-color;
  padding: 0;
  border-radius: 5px;
  font-size: $extra-medium-f-size;
}

.popup_newsletter_overlay {
  display: flex;
  flex-direction: row;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1001;

  .popup_newsletter {
    position: fixed;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1002;

    width: 814px;
    height: 478px;
    background: linear-gradient(180deg, #2B2F3D 0%, #1F222E 66.62%);
    box-shadow: 0px 22px 33px rgba(0, 0, 0, 0.5);
    border-radius: 15px;
    @media only screen and (max-width: 768px) {
      width: 361px;
    }

    @media only screen and (max-width: 400px) {
      width: 90%;
    }

    .closeBtn {
      position: absolute;
      top: 0;
      right: 15px;
      font-size: 40px;
      cursor: pointer;
      z-index: 1001;
      @media only screen and (max-width: 768px) {
        color: #a4a5b6;
      }
    }

    .popup_newsletter__photo {
      background-image: url("../images/newsletter_photo.png");
      background-repeat: no-repeat;
      border-radius: 15px 0 0 15px;
      @media only screen and (max-width: 768px) {
        border-radius: 15px 15px 0 0;
        height: 223px;
        margin-bottom: 15px;
      }
    }

    .join-newsletter_title {
      font-size: 60px !important;
      color: rgb(255, 255, 255);
      font-family: 'Gilroy-Bold', serif;
      @media only screen and (max-width: 768px) {
        font-size: 25px !important;
      }
    }

    .join-newsletter_normal {
      font-family: 'Gilroy-Bold', serif;
      font-style: normal;
      font-weight: normal;
      font-size: 21px !important;
      line-height: 25px;
      color: rgb(255, 255, 255);

      @media only screen and (max-width: 768px) {
        font-size: 15px !important;
      }
    }
  }
}

.offer-details {
  padding: 20px;

  .body {
    margin: auto;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .image-linked {
      max-width: 100%;
    }
  }

  .heart-circle {
    text-align: center;
    font-size: 30px;
  }
}


@media only screen and (max-width: 400px) {
  .offer-details {

    .body {

      width: 90%;
    }
  }


  .justify-content-center-sm {
    justify-content: center;
  }

  .a-slider-sm {
    width: 90%;

    .offer-list-box {
      max-width: 100%;

      img {
        height: auto !important;
      }
    }
  }
  .slider-sm {
    max-width: 100%;

    img {
      height: auto !important;
    }
  }
}

